import React from 'react'
import aboutimg from "../assets/about-img.jpg"

const AboutLeft = () => {
  return (
    <div className="left">
    <img src={aboutimg} alt='About Jebal Rocky' />
</div>
  )
}

export default AboutLeft