import React from 'react'
import FooterTop from './FooterTop'
import Seperator from './Seperator'
import FooterBottom from './FooterBottom'

const Footer = () => {
  return (
    <footer>
        <FooterTop />
        <Seperator />
        <FooterBottom />
    </footer>
  )
}

export default Footer