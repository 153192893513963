import React from 'react'
import headerimage from '../assets/header-img.jpg' 

const MainRight = () => {
  return (
    <>
    <div className="right">
            <img src={headerimage} alt='Jebal Rocky' />
    </div>
    
    </>
  )
}

export default MainRight