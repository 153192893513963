import React from 'react'

const AboutRight = () => {
  return (
    <div className="right">
    <h5>About</h5>
    <h3>About Jebal Rocky Technical Contracting L.L.C.</h3>
    <p>
        Founded in 2008, Jebal Rocky Technical Contracting L.L.C. has established itself as a leading manpower supplier in the UAE, 
        delivering expert labor solutions to businesses across a wide range of industries. 
        We specialize in providing high-quality, skilled, and qualified workforce tailored to meet the unique needs of your projects.
    </p>
    <p>
        As the UAE continues to grow into a global business hub, having access to a reliable and competent workforce is critical for sustained success. 
        At Jebal Rocky, we understand the importance of quality manpower in driving your business forward, 
        and we are committed to helping you meet your staffing requirements efficiently and effectively.
    </p>
    
    <h5>Our Commitment to You</h5>
        <li>
            <b>Client-Centered Approach:</b> We prioritize your satisfaction by listening carefully to your needs and developing solutions that align with your business goals. 
            We believe in building long-term partnerships based on trust and transparency.
        </li>
        <li>
            <b>Tailored Workforce Solutions:</b> Whether you need short-term labor, long-term staffing, 
            or specialized technical expertise, we provide flexible and reliable manpower services to meet the diverse demands of your industry.
        </li>
        <li><b>Compliance & Legal Guidance:</b> Our team stays up-to-date on labor laws and regulations in the UAE, 
            providing you with expert guidance to ensure that all legal requirements are met seamlessly. We take care of the compliance aspects, so you can focus on your business.</li>
    <hr />
            <p>Choose Jebal Rocky Technical Contracting L.L.C. for compliant, efficient, and client-focused labor supply services. We are your trusted partner in building a strong, capable workforce for the future.</p>
</div>
  )
}

export default AboutRight