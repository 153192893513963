import React from 'react'

const SkillsItems = () => {
  return (
    <>
        <div className="skill-items">
            <div className="item">
                <div className="icon"><i className='bx bx-desktop' ></i></div>
                <h4>White-Collar Employment Services</h4>
                <p>
                    We offer tailored recruitment solutions for businesses and job seekers, ensuring a perfect match for roles in administration, finance, management, and more. 
                    Whether you're looking to advance your career or hire skilled talent, we provide personalized support to meet your unique needs.
                </p>
            </div>

            <div className="item">
                <div className="icon"><i className='bx bx-hard-hat' ></i></div>
                <h4>Blue-Collar Employment Services</h4>
                <p>
                    We provide businesses with qualified, hands-on talent while helping job seekers find stable positions that align with their skills and experience. 
                    Whether you're hiring or looking for work, we offer customized solutions to meet your workforce needs.
                </p>
            </div>

            <div className="item">
                <div className="icon"><i className='bx bx-bus'></i></div>
                <h4>Staff Transportation</h4>
                <p>
                    We specialize in offering customized shuttle services that meet the unique needs of businesses, helping improve punctuality and convenience for your workforce. 
                    Whether for daily commutes or special events, we ensure a smooth and hassle-free transportation experience.
                </p>
            </div>

            <div className="item">
                <div className="icon"><i className='bx bx-buildings'></i></div>
                <h4>Engineering Contracting</h4>
                <p>
                    We provide expertise in civil, mechanical, and electrical contracting, ensuring high-quality, cost-effective results for industrial, commercial, and public sector developments. 
                    From project conception to completion, we are committed to delivering innovative, sustainable, and efficient engineering solutions.
                </p>
            </div>
            
        </div>
    </>
  )
}

export default SkillsItems