import React from 'react'

const FooterBottom = () => {
  return (
    <div className="bottom">
            <p>
                Designed by Imperial IT 
                &copy; 2024 Jebal Rocky | <a href="https://jebalrocky.com">jebalrocky.com</a>
            </p>
            <div className="links" id="#links">
                <a href="#">Privacy Policy</a>
                <a href="#">Terms of Service</a>
                <a href="#">Cookies Setting</a>
            </div>
            Sharjah, UAE<h3><i className='bx bxs-phone'>+971 (2) 551 7266</i></h3>
        </div>
  )
}

export default FooterBottom