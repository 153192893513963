import React from 'react'
import SkillsItems from './SkillsItems'

const Skills = () => {
  return (
    <>
    <div className="skills" id="skills">
    <h5>Our Services</h5>
    <h3>Our Expertise</h3>  

    <SkillsItems />
    
    </div>
    </>
  )
}

export default Skills