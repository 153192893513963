import React from 'react'
import ClientReviewMessages from './ClientReviewMessages'

const ClientsReview = () => {
  return (
    <div className="feedback" id="feedback">
        <h5>Clients Reviews</h5>
        <h3>Customers Feedback</h3>
        <ClientReviewMessages />

    </div>
  )
}

export default ClientsReview