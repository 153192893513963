import React from 'react'
import PortfolioItems from './PortfolioItems'

const Portfolio = () => {
  return (
    <>
        <div className="portfolio" id="portfolio">
          <PortfolioItems />  
        </div>
    </>
  )
}

export default Portfolio