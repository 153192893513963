import React from 'react'

import ExpertiseItems from './ExpertiseItems'
import Footer from './Footer'

const ExpericeArea = () => {
  return (
    <>
    <div className='expertise' id='expertise'>
      
        <ExpertiseItems />

    </div>
        <Footer />
        </>
  )
}

export default ExpericeArea