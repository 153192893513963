import React from 'react'
import MainLeft from './MainLeft'
import MainRight from './MainRight'


const Main = () => {
  return (
    <>
    <div className="main" id="main">
        <MainLeft />
        <MainRight />
        
    </div>
    
    

    </>
  )
}

export default Main