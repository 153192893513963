import React from 'react'
import "./Service.css"
import WorkTradeItems from './WorkTradeItems'
import Footer from './Footer'
const WorkTrades = () => {
  return (
    <>
   <WorkTradeItems />
   <Footer />
    </>
  )
}

export default WorkTrades